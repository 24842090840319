// PROD
//const BB_BACKEND = "https://api.levelapp.be/beebonds/admin";

//PREPROD
//const BB_BACKEND = "https://api-preprod.levelapp.be/beebonds/admin";

//STAGING
//const BB_BACKEND = "https://api-staging.levelapp.be/beebonds/admin";

//Local
//const BB_BACKEND = 'https://localhost:5010';

const BB_BACKEND = process.env.REACT_APP_BB_BACKEND
const BB_STORAGE = process.env.REACT_APP_BB_STORAGE

const LAST_UPDATE = "Version Prod : 03/10/2024 - 13.30";

export default { BB_BACKEND, BB_STORAGE, LAST_UPDATE };
